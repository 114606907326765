import { Box, Container, Typography } from '@mui/material';
import styles from './footer.module.css';
import isMobile from '../components/mobile/useInMobile';
import PtechLogoWhite from '@/assets/PROMPT_TECHNOLOGY_LOGO_white.png'
export function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        bgcolor: 'black',
      }}
    >
      <Container maxWidth="xl">
        <Box className='footer' justifyContent={{sm:'start', xs:"start",md:"center", lg:'space-between'}} sx={{
          display: 'flex',
          flexDirection: isMobile() ? 'column' : 'row',
        }}>
          <Box className='footer-image-wrap'>
            <img className='footer-image' src={PtechLogoWhite} />
          </Box>
          <Box className='footer-text-wrap'>
            <Typography className='footer-name-text'>(주)프람트테크놀로지</Typography>
            <Typography className='footer-address-text'>대표이사 : 전종훈</Typography>
            <Typography className='footer-address-text'>서울특별시 강남구 논현로81길 12, 2층(역삼동, 조앤리빌딩)</Typography>
            <Typography className='footer-address-text'>Tel: (02)553-6441</Typography>
            <Typography className='footer-address-text'>Fax: (02)553-6442</Typography>
            <Typography className='footer-copyright-text'>
              Copyright © 2013, by Prompt Technology, Co., Ltd. All Rights Reserved.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
