import React from 'react';
import { Box, Container, Grid, styled, SvgIcon, Typography } from '@mui/material';

interface StepCardProps {
  index: number;
  title: string;
  bg: string;
  text: string;
}

const StepCard: React.FC<StepCardProps> = (props: StepCardProps) => {
  const StepCardWrap = styled(Box)({
    width: '100%',
    color: '#FFF',
  });

  const Header = styled(Grid)({
    display: 'flex',
    backgroundColor: '#B8313D',
    padding: '26px 32px',
  });

  const StepIndex = styled(Typography)({
    fontSize: '24px',
    fontWeight: '700',
  });

  const Title = styled(Typography)({
    fontSize: '24px',
    fontWeight: 700,
  });

  const Body = styled(Box)({
    height: '240px',
    backgroundImage: `url(${props.bg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const BodyText = styled(Typography)({
    fontSize: '30px',
    fontWeight: '700',
    textAlign: 'center',
  });

  return (
    <StepCardWrap>
      <Header container>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <StepIndex>STEP {props.index ? props.index : '0'}.</StepIndex>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Title>{props.title}</Title>
        </Grid>
      </Header>
      <Body>
        <BodyText dangerouslySetInnerHTML={{ __html: props.text }}></BodyText>
      </Body>
    </StepCardWrap>
  );
};

export default StepCard;
