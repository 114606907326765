import { Box, Container, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TopNav from './TopNav';
import { Footer } from './Footer';
import { useState } from 'react';
// import { PropsWithChildren } from 'react';
import MeetingModal from '@/components/modal/MeetingModal';
import { ModalProvider } from "@/components/modal/ModalContext";

/**
 * DefaultLayout
 * @param
 * @param
 * @constructor
 */
//
export default function HomeLayout() {
  const [isNavHide, setIsNavHide] = useState(true);
  return (
    <>
    <ModalProvider>
        <TopNav 
          isNavHide={isNavHide}></TopNav>
          <MeetingModal/>
        <Outlet context={{ isNavHide, setIsNavHide }} />
      </ModalProvider>
    </>
  );
}
