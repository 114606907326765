import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem } from '@mui/material';
import startDoubleQuotation from '@/assets/quotation/start-double-quotation.svg';
import endDoubleQuotation from '@/assets/quotation/end-double-quotation.svg';
import companyDescImage from '@/assets/company/company-desc.png';

const OrganizationMain: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Box className="product-infuser-desc-header-box">
          <Typography className="word-47 main-color">
            <img src={startDoubleQuotation} className="start-double-quotation" />
            차별화된&nbsp;
            <Typography className="word-47 fw-bold main-red-title d-inline">Needs, Technology, Value</Typography>
            <img src={endDoubleQuotation} className="end-double-quotation" />
          </Typography>
          <Typography className="word-47 fw-bold">조화를 꿈꾸는 기업입니다.</Typography>
        </Box>
      </Grid>
      <Box className="infuser-desc-box">
        <img src={companyDescImage} className="company-desc-img" />
      </Box>
    </Box>
  );
};

export default OrganizationMain;
