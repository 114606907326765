import { Box, Container, Grid, Stack } from '@mui/material';
import HomeSubNavigator from './HomeSubNavigator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faListCheck, faLaptop } from '@fortawesome/free-solid-svg-icons';
import { faDocker } from '@fortawesome/free-brands-svg-icons';
import docInfuserImage from '../../assets/imgs/home/doc_infuser_archi.png';
import docInfuserLogoImage from '../../assets/imgs/home/doc_infuser_logo.png';

export default function DataInfuser() {
  return (
    <div className="home-item doc-infuser">
      <Container maxWidth="xl">
        <Grid container>
          <Grid item lg={8} xs={12}>
            <Box component={'div'} display={'flex'} justifyContent={'center'}>
              <Box
                component={'img'}
                alignSelf={{
                  md: 'center',
                }}
                sx={{ width: { xs: '80%', lg: '100%' } }}
                src={docInfuserImage}
                loading="lazy"
              />
            </Box>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Stack className="infuser-features pt-0 text-right m-text-center">
              <Box component="div" className="pb-100 m-pb-rem-1" order={{}}>
                <Box display={'flex'} justifyContent={'center'}>
                  <Box
                    component={'img'}
                    className="doc-infuser-logo"
                    alignSelf={{
                      md: 'center',
                    }}
                    sx={{ width: { xs: '80%', lg: '100%' } }}
                    src={docInfuserLogoImage}
                    loading="lazy"
                  />
                </Box>
                <div className="t-18">
                  <div>DOC, PDF 등 다양한 포맷의 문서로부터 손쉽게</div>
                  <div>내용 추출 및 저장하는 오픈소스 기반 문서 추출 시스템</div>
                </div>
              </Box>
              <Box order={{}}>
                <div className="infuser-feature">
                  <div className="infuser-feature-icon">
                    <FontAwesomeIcon icon={faListCheck} />
                  </div>
                  <div className="infuser-feature-text">손쉬운 추출 규칙 관리</div>
                </div>
              </Box>
              <Box order={{}}>
                <div className="infuser-feature">
                  <div className="infuser-feature-icon">
                    <FontAwesomeIcon icon={faFileInvoice} />
                  </div>
                  <div className="infuser-feature-text">다양한 문서 포맷 지원</div>
                </div>
              </Box>
              <Box order={{}}>
                <div className="infuser-feature">
                  <div className="infuser-feature-icon">
                    <FontAwesomeIcon icon={faLaptop} />
                  </div>
                  <div className="infuser-feature-text">무설치 실행 환경 제공</div>
                </div>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
