import HomeSubNavigator from '@/views/home/HomeSubNavigator';
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import useIsMobile from '../mobile/useInMobile';
import { useModal } from '@/components/modal/ModalContext';

interface CustomControlsProps {
  className?: string;
  getCurrentSlideIndex: () => number;
  onNext: () => void;
  onPrev: () => void;
  scrollToSlide: (index: number) => void;
  slidesCount: number;
  style?: React.CSSProperties;
  controlButtonProps: ButtonProp;
}

export interface ButtonProp {
  pageNumber: number;
  title: string;
  onSlideChange: (slideIndex: number) => void;
}

const activeNames = ['', '', 'data-infuser', 'data-verifier', 'doc-infuser', 'datamap', 'qufa-studio'];

export default function ControlButton(props: CustomControlsProps): JSX.Element {
  const {
    className = 'full-page-controls',
    getCurrentSlideIndex,
    onNext,
    onPrev,
    scrollToSlide,
    slidesCount,
    controlButtonProps,
  } = props;
  const isMobile = useIsMobile();
  const { isModalOpen } = useModal();

  const currentSlideIndex = isModalOpen ? 0 : getCurrentSlideIndex();
  useEffect(() => {
    controlButtonProps.onSlideChange(currentSlideIndex);
  }, [currentSlideIndex]);

  // 원복
  // const currentSlideIndex = getCurrentSlideIndex();
  // useEffect(() => {
  //   controlButtonProps.onSlideChange(currentSlideIndex);
  // });

  const displayStyle = isMobile || isModalOpen || activeNames[currentSlideIndex] === '' ? 'none' : 'block';

  const handleOnClick = (slideIndex: number) => {
    scrollToSlide(slideIndex);
  };

  return (
    <Container maxWidth="xl" style={{ display: displayStyle }}>
      <HomeSubNavigator active={activeNames[currentSlideIndex]} moveSlide={handleOnClick} />
    </Container>
  );
}
