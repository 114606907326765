import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem, Divider } from '@mui/material';
import bigdataBgImg from '@/assets/imgs/home/bigdata_slide_bg.png';
import companyOrganizationImage from '@/assets/company/company-organization.png';
import dataInfuserManager from '@/assets/product/data-finsuer-designer.png';
import companyBusinessImage from '@/assets/company/company-business.png';

const OrganizationChart: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Box className="product-infuser-character-header-box">
          <Typography className="word-47">조직 및 인원</Typography>
        </Box>
        <Box className="infuser-desc-row">
          <Typography className="infuser-desc-sub">산학 연계 조직 구성으로&nbsp;</Typography>
          <Typography className="infuser-desc-sub bold-word">R&D분야의 차별화된 전문성 및 인력</Typography>
          <Typography className="infuser-desc-sub">을 확보하고 있습니다.</Typography>
        </Box>

        <Box className="infuser-desc-box">
          <img src={companyOrganizationImage} className="company-desc-img" />
        </Box>
      </Grid>
    </Box>
  );
};

export default OrganizationChart;
