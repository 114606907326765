import { Container, Grid } from '@mui/material';
import HomeSubNavigator from './HomeSubNavigator';

import datamapBackground from '../../assets/imgs/home/datamap_background.png';
import datamapOverlay from '../../assets/imgs/home/datamap_overlay.png';

export default function Datamap() {
  return (
    <div className="home-item datamap" style={{ backgroundImage: `url(${datamapBackground})` }}>
      <div className="datamap-overlay" style={{ backgroundImage: `url(${datamapOverlay})` }}></div>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <div className="datamap-text-wrap pt-20-p">
              <div className="datamap-title mb-20 t-40 t-bold">Data Map (데이터맵)</div>
              <div className="datamap-content t-18">
                <div>AI 기반의 유사도 분석 결과를 시각화 서비스로 제공 및</div>
                <div>연계된 모든 메타데이터를 손쉽게 확인 가능</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
