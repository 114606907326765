import React from "react";
import Modal from "react-modal";
import { Box, Table, TableRow, TableCell, Typography, Button, IconButton} from "@mui/material";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ptechImage from '@/assets/PROMPT_TECHNOLOGY_LOGO_white.png';
import { useModal } from "@/components/modal/ModalContext";


const handleDownload = (fileUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const MeetingModal: React.FC = () => {
  const { isModalOpen, setIsModalOpen } = useModal();
useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => document.body.classList.remove("modal-open");
  }, [isModalOpen]);

  if (!isModalOpen) return null;

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      className="modalContent"
      overlayClassName="modalOverlay"
      portalClassName={`modal-root ${isModalOpen ? "active" : ""}`}
    >
      <Box className="modalWrapper">
        <Box className="modalHeader">
          <img src={ptechImage} className="logo" />
          <Box className="modalTitle">정기주주총회 소집 공고</Box>
          <IconButton className="closeButton" onClick={() => setIsModalOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className="modalBody">
          <Box>
            <Typography className="contentHead mb-1">여러 주주님의 건승과 평안하심을 기원합니다.</Typography>
            <Typography className="contentHead">당사는 상법 제 365조 및 정관 제 19조에 의거 아래와 같이</Typography>
            <Typography className="contentHead mb-1">정기주주총회를 개최하오니 참석하여 주시기 바랍니다.</Typography>
          </Box>

          <Box className="pt-20">
            <Table className="contentTable">
                  <TableRow >
                    <TableCell >일&ensp;&ensp;시</TableCell>
                    <TableCell>
                      <Typography className="baseFont">2025년 3월 27일 18시</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell>장&ensp;&ensp;소</TableCell>
                    <TableCell>
                      <Typography className="baseFont">서울특별시 강남구 논현로81길 12, 1층(E/L 2층) 대회의실</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>회의 목적사항</TableCell>
                    <TableCell>
                      <Typography className="baseFont">가. 보고 사항 : 영업보고</Typography>
                      <Typography className="baseFont">나. 부의 의견</Typography>
                      <Typography className="baseFont">&ensp;&ensp;제1호 의안 : 결산 재무제표 승인의 건</Typography>
                    </TableCell>
                  </TableRow>
            </Table>

                <Typography className="contentDescription">
                  정관 제28조에 의거 의결권의 대리행사가 가능하며 <br />
                  대리인의 참석시 총회 개시 전에 위임장을 제출하여 주시기 바랍니다.
                </Typography>
          </Box>

            <Box className="contentFoot">
              <Typography className="baseFont" sx={{display: "inline" }}>주식회사 프람트 테크놀로지 </Typography>
              <Typography className="baseFont" sx={{display: "inline", fontWeight: 'bold'}}>대표이사 전 종 훈</Typography>
            </Box>
          
        </Box>

      <Box className="modalFooter">
        <Button className="button"
        onClick={() => handleDownload("/pdf/주주총회양식_20250327.pdf", "정기주총안내_20250327.pdf")}>
        <Typography className="baseFont">주주총회 양식 다운로드</Typography>
        </Button>
      </Box>
      </Box>
    </Modal>
  );
  
};

export default MeetingModal;
